<template>
  <div v-if="invoice!=null" >
    <InvoiceDetails :invoice="invoice"></InvoiceDetails>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BFormGroup, BFormInput, BPagination, BTr, BTh, BButton, BLink  } from 'bootstrap-vue'
import InvoiceDetails from "@/components/Invoice/InvoiceDetails";
import CustomLoader from "@/components/Common/CustomLoader";
import Ripple from 'vue-ripple-directive'

export default {
  name: "Invoice",
  components: {
    BTable, BRow, BCol, BFormGroup, BFormInput, BPagination, BTr, BTh, BButton, BLink, CustomLoader, InvoiceDetails
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      invoice: null,
      isLoading: false,
    }
  },
  created(){
    this.getApiData();
  },
  methods: {
    getApiData() {
      this.isLoading = true;
      this.items = [];
      this.$http.post('/invoice/admin/show/'+this.$route.params.id,{}).then(response => {
        if (response.data.status == 200) {
          this.invoice = response.data.value;
        }
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
