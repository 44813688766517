<template>
    <b-card v-if="item">
        <div class="col-md-12" v-if="!auditTrial">
            <div class="row justify-content-between mr-0 mb-2">
                <div class="col-md-6">
                    <h3 class="font-weight-bolder">
                        <b-link class="btn btn-outline-primary" to="/invoices">
                                <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
                            {{ $t("back") }}
                        </b-link>
                        {{ $t("invoice_id") }} : I-{{ item.id }}
                    </h3>
                </div>
                <div align="end">
                    <InvoiceStatus :invoice="item" :status="item.status"/>
                    <div>
                        <h4>
                            <b-badge :variant="item.hide_from_market == 1 || item.status == 11  ? 'danger' : 'success'">
                                {{ item.hide_from_market == 1 || item.status == 11 ? 'This invoice is hidden from the market.' : 'This invoice is visible in the market' }}
                            </b-badge>
                        </h4>
                    </div>
                </div>
            </div>
            <b-row class="ml-1 mr-1 mb-2" v-if="item.status == 2 && item.msg != null">
                <h4>{{$t('rejected_reason')}}</h4>
            </b-row>
            <b-row class="ml-1 mr-1" v-if="(item.status == 12 || item.status == 2) && item.msg != null">
                <b-col :class="(item.status == 12 && item.msg != null) || item.status == 2 ? 'alert alert-danger' : 'alert alert-secondary'
          " class="p-2 col-md-12">
                    <h4>
                        <b-avatar :variant="(item.status == 12 && item.msg != null) || item.status == 2 ? 'light-danger' : 'light-secondary'" class="ml-1 mr-1" size="45">
                            <feather-icon icon="AlertTriangleIcon" size="21"/>
                        </b-avatar>
                        <span :class="(item.status == 12 || item.status == 2) && item.msg != null ? 'text-danger' : 'text-secondary'" class="font-lg" v-if="item.msg">{{
              $t(item.msg) }}
            </span>
                    </h4>
                </b-col>
            </b-row>

            <div class="pa-0">
                <b-col class="justify-center text-center text-black-50">
                    <b-row class="m-0 pb-0">
                        <b-col class="pt-1 pb-0" cols="12">
                            <b-button :title="$t('flash_sale')" class="mr-1 mb-1" v-b-tooltip.hover variant="outline-primary">
                                <FeatherIcon icon="StarIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button :title="$t('assign_to_book')" class="mr-1 mb-1" v-b-tooltip.hover variant="outline-primary">
                                <FeatherIcon icon="BookIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button :title="$t('list_for_sale_to_public_market')" class="mr-1 mb-1" v-b-tooltip.hover variant="outline-primary">
                                <FeatherIcon icon="DollarSignIcon" size="17"></FeatherIcon>
                            </b-button>

                            <b-button
                                :title="$t('audit_trial')"
                                @click="showAduitTrial" class="mr-1 mb-1"
                                v-b-tooltip.hover
                                variant="outline-primary"
                            >
                                <FeatherIcon icon="FileIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button :title="$t('custom_scoring')" @click="openCustomScoring()" class="mr-1 mb-1" v-b-tooltip.hover variant="outline-warning">
                                <FeatherIcon icon="ActivityIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t((item.is_reverse == 1 ? 'send_supplier_confirmation' : 'send_initial_debtor_confimration'))"
                                @click="changeModalInfo(
                                    (item.is_reverse == 1 ? 'send_supplier_confirmation' : 'send_initial_debtor_confimration'),
                                    $t((item.is_reverse == 1 ? 'send_supplier_confirmation' : 'send_initial_debtor_confimration')),
                                    $t('are_you_sure')
                                )"
                                class="mr-1 mb-1" v-b-modal.approveModal
                                v-b-tooltip.hover
                                v-if="item.status == 5 || item.status == 6"
                                variant="outline-dark"
                            >
                                <FeatherIcon icon="MailIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('score_invoice')"
                                @click="changeModalInfo(
                                        'score',
                                        $t('score_invoice'),
                                        $t('are_you_sure')
                                )"
                                class="mr-1 mb-1" v-b-modal.approveModal
                                v-b-tooltip.hover
                                v-if="item.status != 1"
                                variant="outline-success"
                            >
                                <FeatherIcon icon="ActivityIcon" size="17"></FeatherIcon>
                            </b-button>

                            <b-button
                                :title="$t('set_inactive')"
                                @click="changeModalInfo(
                                      'status',
                                      $t('status'),
                                      $t('are_you_sure_you_want_set_to_inactive')
                                )"
                                class="mr-1 mb-1"
                                v-b-modal.approveModal v-b-tooltip.hover
                                v-if="item.status == 1"
                                variant="outline-danger"
                            >
                                <FeatherIcon icon="XIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('set_active')"
                                @click="changeModalInfo(
                                    'activate',
                                    $t('activate'),
                                    $t('are_you_sure_you_want_set_to_active')
                                 )"
                                class="mr-1 mb-1"
                                v-b-modal.approveModal v-b-tooltip.hover
                                v-else
                                variant="outline-success"
                            >
                                <FeatherIcon icon="CheckIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('settled')"
                                @click="changeModalInfo(
                                    'settled',
                                    $t('settled'),
                                    $t('are_you_sure')
                                )"
                                class="mr-1 mb-1"
                                v-b-modal.approveModal
                                v-b-tooltip.hover
                                v-if="item.status == 10" variant="outline-warning"
                            >
                                <FeatherIcon icon="CheckCircleIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('remove')"
                                @click="changeModalInfo(
                                    'remove',
                                    $t('remove'),
                                    $t('are_you_sure_you_want_to_remove_this_invoice')
                                )"
                                class="mr-1 mb-1"
                                v-b-modal.approveModal
                                v-b-tooltip.hover v-if="item.status != 1"
                                variant="outline-danger">
                                <FeatherIcon icon="TrashIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="item.hide_from_market == 1 ? $t('show_in_market') : $t('hide_from_market')"
                                :variant="item.hide_from_market == 1 ? 'outline-success' : 'outline-danger'"
                                @click="changeModalInfo(
                                      'hide_from_market',
                                      item.hide_from_market == 1 ? $t('show_in_market') : $t('hide_from_market'),
                                      item.hide_from_market == 1 ? $t('are_you_sure_you_want_to_show_this_invoice') : $t('are_you_sure_you_want_to_hide_this_invoice')
                                )"
                                class="mr-1 mb-1" v-b-modal.approveModal
                                v-b-tooltip.hover>
                                <FeatherIcon :icon="item.hide_from_market == 1 ? 'EyeIcon' : 'EyeOffIcon'" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('set_pending')"
                                @click="changeModalInfo(
                                    'pending',
                                    $t('set_pending'),
                                    $t('are_you_sure')
                                )"
                                class="mr-1 mb-1" v-b-modal.approveModal
                                v-b-tooltip.hover
                                v-if="item.status != 1"
                                variant="outline-primary"
                            >
                                <FeatherIcon icon="ClockIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('force_activate')"
                                @click="forceActivate(item)"
                                class="mr-1 mb-1" v-b-tooltip.hover
                                v-if="item.status == 5 || item.status == 6"
                                variant="outline-primary"
                            >
                                <FeatherIcon icon="ActivityIcon" size="17"></FeatherIcon>
                                <FeatherIcon icon="CheckIcon" size="17"></FeatherIcon>
                            </b-button>
                            <b-button
                                :title="$t('send_offer')"
                                @click="changeModalInfo(
                                        'send_offer',
                                        $t('send_offer'),
                                        $t('are_you_sure')
                                )"
                                class="mr-1 mb-1" v-b-modal.approveModal
                                v-b-tooltip.hover
                                v-if="item.status != 1 && item.status != 10 && item.status != 11"
                                variant="outline-warning"
                            >
                                <FeatherIcon icon="SendIcon" size="17"></FeatherIcon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </div>

            <div class="mt-2" v-if="item.scorings.length > 0">
                <b-col class="">
                    <div align="end" class="font-weight-bolder text--gray">
                        {{ $t("Admin Additional Information") }}
                    </div>
                    <b-row>
                        <b-table
                            :fields="fields"
                            :items="[item.scorings[item.scorings.length - 1]]"
                            hover
                            responsive=""
                            striped
                        >
                            <template v-slot:head()="data">
                                <table-header :data="data">
                                    <div v-if="data.column != 'debtor'"> {{ data.label }}</div>
                                    <div v-else>
                                        {{ item.is_reverse == 1 ? $t("supplier") : $t("debtor") }}
                                    </div>
                                </table-header>
                            </template>
                            <template #cell(score)="">
                <span>{{
                  invoice.weight_score ? invoice.weight_score : ""
                }}</span>
                            </template>
                            <template #cell(terms)="">
                                <span>{{ invoice.terms }}</span>
                            </template>
                            <template #cell(created_at)="data">
                                <span>{{ data.item.created_at | formatDate }}</span>
                            </template>
                            <template #cell(seller)="">
                <span v-if="invoice.company">
                  <span> {{ invoice.company.name }} </span
                  ><span v-html="'('"> </span>
                  <span v-if="invoice.seller_score">
                    {{ invoice.seller_score }}
                  </span>
                  <span v-html="')'"> </span>
                </span>
                            </template>
                            <template #cell(debtor)="">
                <span v-if="invoice.debtor">
                  <span> {{ invoice.debtor.name }} </span
                  ><span v-html="'('"> </span>
                  <span v-if="invoice.debtor_score">
                    {{ invoice.debtor_score }}
                  </span>
                  <span v-html="')'"> </span>
                </span>
                            </template>
                        </b-table>
                    </b-row>
                </b-col>
            </div>
            <div class="mt-2" v-if="item.scorings.length > 0">
                <b-col class="alert alert-success p-2 text-black-50">
                    <b-row class="m-0" v-if="item.status == 1">
                        <b-col>
                            <b-avatar size="45" variant="light-success">
                                <feather-icon icon="AlertTriangleIcon" size="25"/>
                            </b-avatar>
                            <span class="mb-25 font-weight-bolder">
                {{ $t("congrats") }}
                {{ $t("your_invoice_is_eligible_for_sale") }}
              </span>
                        </b-col>
                    </b-row>
                    <b-row class="m-0 col-md-12">
                        <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                            <h5>
                                <span class="font-weight-bolder">{{ $t("rating") }}</span>
                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>
                                {{ invoice.scorings[0].rating }}
                            </h5>
                        </b-col>
                        <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                            <h5>
                <span class="font-weight-bolder">{{
                   item.is_reverse == 1 ? $t("surcharge_in_%") : $t("discount_in_%")
                }} </span>
                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>
                                <p>
                                    {{ invoice.scorings[0].discount_in_percentage }} %
                                    <button @click="modalStatus = true" class="btn">
                                        <feather-icon icon="EditIcon" size="18"/>
                                    </button>
                                </p>

                            </h5>
                        </b-col>
                        <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                            <h5>
                                <span class="font-weight-bolder"> {{ $t("type") }}</span>
                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>
                                <span> {{ item.is_reverse == 1 ? $t("Reverse") : (item.recourse == 0 ? $t("Non-Recourse") : $t("Recourse")) }} </span>
                            </h5>
                        </b-col>

                        <b-col class="font-weight-bold text-black-50 p-1" md="4">
                            <h5>
                <span class="font-weight-bolder">{{
                  $t("effective_value")
                }}</span>
                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>
                                {{ amountFormat(item.scorings[0].amount_with_discount, item.currency.name ) }}

                            </h5>
                        </b-col>
                        <b-col class="font-weight-bold text-black-50 p-1" md="4">
                            <h5>
                                <span class="font-weight-bolder">  {{ item.is_reverse == 1 ? $t("surcharge") : $t("discount") }} </span>
                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>
                                {{ amountFormat(item.scorings[0].discount_from_amount, item.currency.name) }}
                            </h5>
                        </b-col>
                        <b-col class="font-weight-bold text-black-50 p-1" md="4">
                            <h5>
                <span class="font-weight-bolder">
                  {{ item.is_reverse == 1 ? $t("supplier_confirmation") : $t("proof_of_ownership") }}</span
                >

                                <feather-icon icon="HelpCircleIcon" size="18"/>
                                <br/>

                                <span v-if="item.debtor_confirmed == 1">
                  {{ $t("confirmed") }}</span
                                >
                                <span v-else-if="item.recourse == 1">{{ $t("not_needed") }}</span>
                                <span v-else> {{ $t("required") }}</span>
                            </h5>
                        </b-col>
                    </b-row>
                </b-col>
            </div>

            <b-form>
                <div class="mt-4">
                    <b-row class="mr-1" m-0 p-0 v-if="!load">
                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="(item.is_reverse == 1 ? $t('supplier') : $t('deptor')) + ':'"
                                class="font-weight-bolder"
                                id="deptor"
                                label-for="deptor"
                                v-if="showAutoSuggest"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="debtor"
                                    rules="required"
                                >
                                    <b-form-select
                                        :options="companies"
                                        disabled
                                        text-field="name"
                                        v-model="item.issuer_id"
                                        value-field="id"
                                    >
                                    </b-form-select>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group
                                :label="(item.is_reverse == 1 ? $t('supplier') : $t('deptor')) + ':'"
                                class="font-weight-bolder"
                                id="deptor"
                                label-for="deptor"
                                v-else
                            >
                                <vue-autosuggest
                                    :input-props="{
                    id: 'autosuggest__input',
                    class: 'form-control',
                  }"
                                    :limit="10"
                                    :suggestions="filteredOptions"
                                    @keyup="searchForCompaniesName(item)"
                                    @selected="onSelected($event, item)"
                                    v-model="item.company_name"
                                >
                                    <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item"
                    >{{ suggestion.item }}
                    </span>
                                    </template>
                                </vue-autosuggest>
                                <b-button
                                    class="mt-1"
                                    disabled
                                    v-if="showHint"
                                    variant="primary"
                                >
                                    <b-spinner small type="grow"></b-spinner>
                                    {{ $t("loading") }}...
                                </b-button>
                                <div
                                    class="alert alert-danger p-1 mt-1"
                                    v-if="companyMessage.length > 0"
                                >
                                    {{ companyMessage }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" v-if="item.status == 0 && item.debtor == null" xl="6">
                            <b-button @click="selectNewDebtor = true" class="mt-md-2">{{ $t("select_debtor") }}
                            </b-button>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="$t('invoice_amount') + ':'"
                                class="font-weight-bolder"
                                id="amount"
                                label-for="amount"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="amount"
                                    rules=""
                                >
                                    <input
                                        @focusout="parsedAmount1($event, item.amount)"
                                        class="form-control"
                                        id="amount-input"
                                        placeholder="00'000.00"
                                        v-model="item.amount"
                                    />

                                    <small class="text-danger" v-if="numbersAmountError">{{
                                        numbersAmountError
                                        }}
                                    </small>
                                    <br v-if="numbersAmountError"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="$t('issue_date') + ':'"
                                class="font-weight-bolder"
                                id="issue_date"
                                label-for="issue_date"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="issue_date"
                                    rules="required"
                                >
                                    <b-form-datepicker
                                        :locale="
                      $store.state.app && $store.state.app.language == 'de'
                        ? 'de'
                        : 'en'
                    "
                                        :max="disabledDates"
                                        :placeholder="$t('choose_a_date')"
                                        :state="errors.length > 0 ? false : null"
                                        @input="changeDueDate(item)"
                                        id="issue_date"
                                        no-flip
                                        required
                                        v-model="item.issue_date"
                                    ></b-form-datepicker>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="$t('payment_term_in_days')  + ' (' + $t('calculated') + ') :'"
                                class="font-weight-bolder"
                                id="payment_date"
                                label-for="payment_date"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="payment_date"
                                    rules="required"
                                >
                                    <b-form-input
                                        @input="changeTerms(item)"
                                        disabled
                                        id="payment_date"
                                        required
                                        type="number"
                                        v-model="item.terms"
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small class="text-danger" v-if="errorDueDate.length > 0">{{
                                        errorDueDate
                                        }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="$t('due_date') + ':'"
                                class="font-weight-bolder"
                                id="due_date"
                                label-for="due_date"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="due_date"
                                    rules="required"
                                >
                                    <b-form-datepicker
                                        :locale="
                      $store.state.app && $store.state.app.language == 'de'
                        ? 'de'
                        : 'en'
                    "
                                        :placeholder="$t('choose_a_date')"
                                        @input="changeTerms(item)"
                                        id="due_date"
                                        no-flip
                                        required
                                        v-model="item.due_date"
                                    ></b-form-datepicker>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" md="6" sm="12" xl="6">
                            <b-form-group
                                :label="$t('invoice_number') + ':'"
                                class="font-weight-bolder"
                                id="invoice_num"
                                label-for="due_date"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="invoice_num"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="invoice_num"
                                        required
                                        v-model="item.invoice_number"
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>


                        <b-col sm="12" v-if="item.is_reverse == 1">
                            <b-row>
                                <b-col lg="6" md="6" sm="12" xl="6">
                                    <b-form-group :label="$t('bic') + ':'" class="font-weight-bolder" label-for="bic">
                                        <validation-provider #default="{ errors }" :name="$t('bic')" rules="required">
                                            <b-form-input id="bic" v-model.trim="item.bic"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" md="6" sm="12" xl="6">
                                    <b-form-group :label="$t('iban') + ':'" class="font-weight-bolder" label-for="iban">
                                        <validation-provider #default="{ errors }" :name="$t('iban')" rules="required">
                                            <b-form-input id="iban" v-model.trim="item.iban"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" md="6" sm="12" xl="6">
                                    <b-form-group :label="$t('bank_address') + ':'" class="font-weight-bolder" label-for="bank_address">
                                        <validation-provider #default="{ errors }" :name="$t('bank_address')" rules="required">
                                            <b-form-input id="bank_address" v-model.trim="item.bank_address"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" md="6" sm="12" xl="6">
                                    <b-form-group :label="$t('bank_name') + ':'" class="font-weight-bolder" label-for="bank_name">
                                        <validation-provider #default="{ errors }" :name="$t('bank_name')" rules="required">
                                            <b-form-input id="bank_name" v-model.trim="item.bank_name"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col
                            align="end"
                            class="mt-2"
                            cols="6"
                            lg="6"
                            md="6"
                            v-if="showUpdateButton"
                            xl="6"
                        >
                            <b-button
                                @click="editInvoice()"
                                variant="primary"
                            >
                                {{ $t("update_invoice_data") }}
                            </b-button
                            >
                        </b-col>
                    </b-row>
                    <b-row cols="12" lg="6" md="6" v-else xl="6">
                        <img src="/loader.svg"/>
                    </b-row>
                </div>
            </b-form>
            <b-row class="mt-2 alert alert-secondary">
                <b-col class="row  row mt-1 ml-1 mr-1  pt-2 pb-2">
                    <b-col class="pt-0 alert alert-light p-2" md="12">
                        <b-form-checkbox
                            @input="changeInvoiceFact('silent', silentFactoring)"
                            disabled
                            false-value="false"
                            name="check-button"
                            switch
                            true-value="true"
                            v-model="silentFactoring"
                        >
                            {{ $t("silent_factoring") }}
                            <feather-icon :title="$t('silent_factoring_tooltip')" icon="HelpCircleIcon" size="18" v-b-tooltip.hover/>
                        </b-form-checkbox>
                    </b-col>
                    <b-col class="pb-0 mb-0 alert alert-light p-2" md="12">
                        <b-form-group class="d-flex pb-0 mb-0" v-slot="{ ariaDescribedby }">

                            <div class="col-12 d-flex">
                                <b-form-radio :aria-describedby="ariaDescribedby" :disabled="item.status != 0" :title="$t(options[0].title)" @change="changeInvoiceFact('recourse',recourse)" class="mr-2" id="nonRecourseTooltip" v-b-tooltip.hover
                                              v-model="item.recourse" value="0">
                                    {{ $t('Non-recourse') }}
                                </b-form-radio>
                                <b-form-radio :aria-describedby="ariaDescribedby" :disabled="item.status != 0" :title="$t(options[1].title)" @change="changeInvoiceFact('recourse',recourse)" class="mr-2" id="recourseTooltip" v-b-tooltip.hover
                                              v-model="item.recourse" value="1"> {{ $t('Recourse') }}
                                    <small>{{ $t('recourse_text') }}</small>
                                </b-form-radio>
                                <b-form-radio :aria-describedby="ariaDescribedby" :disabled="item.status != 0" :title="$t(options[2].title)" @change="changeInvoiceFact('recourse',2)" class="mr-2" id="reverseTooltip" v-b-tooltip.hover
                                              v-model="item.recourse" value="2"> {{ $t('Reverse') }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col
                        class="mt-0"
                        lg="12"
                        md="12"
                        sm="12"
                        v-if="item.recourse == 1"
                        xl="12"
                    >
                        <div class="col-12  p-2">
                            <div v-if="$store.state.app.language == 'en'">
                                I hereby confirm the following agreement within the framework of recourse factoring:
                                <br/>
                                <br/>
                                1.1 The Seller transfers its receivables from deliveries and services to the Buyer. The Buyer assumes these receivables on account of fulfilment subject to Clause 1.2 and § 3 of this agreement.
                                <br/>
                                <br/>
                                1.2 This is a case of recourse factoring. This means that the seller assumes the default risk for the assigned receivables.
                                <br/>
                                <br/>
                                1.3 The debtor pays the amount of the claim to the seller. The seller then forwards the claim amount received from the debtor to the buyer within 3 days of receipt.
                                <br/>
                                <br/>
                                1.4 If the debtor has not paid the amount of the claim to the seller by the due date, the seller shall pay the amount of the claim to the buyer no later than the third day after the due date (credit note required).
                                <br/>
                                <br/>
                                1.5 The Seller shall bear all costs incurred as a result of non-payment, including reminder and collection costs.
                            </div>
                            <div v-else-if="$store.state.app.language == 'de'">
                                Hiermit bestätige ich folgende Vereinbarung im Rahmen des Recourse-Factoring:
                                <br/>
                                <br/>
                                1.1 Der Verkäufer überträgt seine Forderungen aus Lieferungen und Leistungen an den Käufer. Der Käufer übernimmt diese Forderungen erfüllungshalber vorbehaltlich Ziff. 1.2 und § 3 dieses Vertrages.
                                <br/>
                                <br/>
                                1.2 Es handelt sich um ein Recourse Factoring. Das bedeutet, dass der Verkäufer das Ausfallrisiko für die abgetretenen Forderungen übernimmt.
                                <br/>
                                <br/>
                                1.3 Der Schuldner begleicht den Forderungsbetrag an den Verkäufer. Der Verkäufer leitet dann, den durch den Schuldner erhaltenen Forderungsbetrag, innerhalb von 3 Tagen nach Erhalt an den Käufer weiter.
                                <br/>
                                <br/>
                                1.4 Falls der Schuldner den Forderungsbetrag bis zum Fälligkeitsdatum nicht an den Verkäufer beglichen haben sollte, begleicht der Verkäufer den Forderungsbetrag gegenüber dem Käufer spätestens am dritten Tag nach dem Fälligkeitsdatum (Gutschrift erforderlich).
                                <br/>
                                <br/>
                                1.5 Der Verkäufer übernimmt alle Kosten, die durch einen Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.
                            </div>

                            <div class="col-12 pl-0 pt-2">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('accept_recourse')"
                                        rules="required"
                                    >
                                        <b-form-checkbox
                                            :disabled="item.status != 0"
                                            :state="errors.length > 0 ? false : null"
                                            @change="changeInvoiceFact('accept_recourse',accept_recourse)"
                                            required
                                            v-model.trim="accept_recourse"
                                        >
                          <span>
                            {{ $t("accept") }}
                          </span>
                                        </b-form-checkbox>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <b-col
                        class="mt-0"
                        lg="12"
                        md="12"
                        sm="12"
                        v-if="item.recourse == 2"
                        xl="12"
                    >
                        <div class="col-12 alert alert-secondary p-2">
                            <div v-if="$store.state.app.language === 'en'">
                                I hereby confirm the following agreement within the framework of reverse factoring:
                                <br>
                                <br>
                                1.1 The contractual partner (debtor) transfers its liabilities from deliveries and services to the invoice purchaser (factor). This invoice purchaser (factor) shall assume these liabilities on account of fulfilment subject to clauses 1.2 and 1.3 of this agreement.
                                <br>
                                <br>
                                1.2 This is a case of reverse factoring. This means that the contractual partner (debtor) retains the default risk for the transferred liabilities.
                                <br>
                                <br>
                                1.3 The contractual partner (debtor) pays the amount of its liability to Aequitex AG. The contractual partner (debtor) transfers the full amount of his liabilities to Aequitex AG on the due date stated on the invoice (credit note required).
                                <br>
                                <br>
                                1.4 The contractual partner (debtor) assumes all costs arising from his payment default, including reminder and collection costs.
                            </div>
                            <div v-else-if="$store.state.app.language === 'de'">
                                Hiermit bestätige ich folgende Vereinbarung im Rahmen des Reverse-Factorings:
                                <br>
                                <br>
                                1.1 Der Vertragspartner (Debtor) übergibt seine Verbindlichkeiten aus Lieferungen und Leistungen an den Rechnungskäufer (Factor) ab. Dieser Rechnungskäufer (Factor) übernimmt diese Verbindlichkeiten erfüllungshalber vorbehaltlich Ziff. 1.2 und 1.3 dieses Vertrages.
                                <br>
                                <br>
                                1.2 Es handelt sich um ein Reverse Factoring. Das bedeutet, dass der Vertragspartner (Debtor) das Ausfallrisiko für die übergebenen Verbindlichkeiten behält.
                                <br>
                                <br>
                                1.3 Der Vertragspartner (Debtor) begleicht den Betrag seiner Verbindlichkeit an die Aequitex AG. Der Vertragspartner (Debtor) überweist Aequitex AG den vollen Betrag seiner Verbindlichkeiten zum auf der Rechnung angegebenen Fälligkeitsdatum (Gutschrift erforderlich).
                                <br>
                                <br>
                                1.4 Der Vertragspartner (Debtor) übernimmt alle Kosten, die durch seinen Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.
                            </div>

                            <div class="col-12 pl-0 pt-2">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('accept_reverse')"
                                        rules="required"
                                    >
                                        <b-form-checkbox
                                            :disabled="item.status != 0"
                                            checked="true"
                                            required
                                        >
                          <span>
                            {{ $t("accept") }}
                          </span>
                                        </b-form-checkbox>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="pt-0 alert alert-light p-2" md="12">
                        <b-form-checkbox
                            @input="changeInvoiceFact('flash_sale', flashSale)"
                            disabled
                            name="check-button"
                            switch
                        >
                            {{ $t("flash_sale") }}
                            <feather-icon icon="HelpCircleIcon" size="18"/>
                        </b-form-checkbox>
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
                    <b-col class="pb-0 mb-0" md="12">
                        <div class="text-decoration-underline">
                            {{ $t("diese_AGB_gelten") }}
                        </div>

                        <ul>
                            <li>{{ $t("diese_AGB_gelten_A") }}</li>
                            <li>{{ $t("diese_AGB_gelten_B") }}</li>
                            <li>{{ $t("diese_AGB_gelten_C") }}</li>
                        </ul>
                        <div class="row col-md-12">
                            <b-checkbox v-model="agreeTerms"></b-checkbox>
                            <label for="">{{ $t("i_have_read_and_agree_with") }}</label>
                        </div>
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col align="end" class="mr-1" md="12">
                    <b-row align="end" class="mt-1 justify-content-end">
                        <b-button
                            @click="
                changeModalInfo(
                  'remove',
                  $t('remove'),
                  $t('are_you_sure_you_want_to_remove_this_invoice')
                )
              "
                            class="mr-1 mb-1"
                            v-b-modal.approveModal
                            variant="primary"
                        >{{ $t("remove") }}
                        </b-button
                        >
                        <b-button
                            @click="
                changeModalInfo(
                  'archive',
                  $t('archive'),
                  $t('are_you_sure_you_want_to_archive_this_invoice')
                )
              "
                            class="mr-1 mb-1"
                            v-b-modal.approveModal
                            variant="primary"
                        >{{ $t("archive") }}
                        </b-button
                        >
                        <b-button class="mr-2 mb-1" disabled variant="primary"
                        >{{ $t("sell_invoice") }} 1
                        </b-button
                        >
                    </b-row>
                </b-col>
            </b-row>
            <div
                class=""
                v-if="item.fractions_bought && item.fractions_bought.length > 0"
            >
                <div class="col-md-12 mt-1 pl-0 mr-1 pt-2 pb-2">
                    <div align="end" class="font-weight-bolder text--gray">
                        {{ $t("fractions_bought") }}
                    </div>

                    <b-table
                        :fields="fractionFields"
                        :items="item.fractions_bought"
                        id="fraction-table"
                        responsive
                        striped
                    >
                        <template #head()="scope">
                            <div class="text-nowrap">{{ $t(scope.label) }}</div>
                        </template>

                        <template #cell(currency)="">
                            <span>CHF</span>
                        </template>
                        <template #cell(buyer)="data">
              <span v-if="data.item.company && data.item.company.name">{{
                data.item.company.name
              }}</span>
                            <span
                                v-else-if="data.item.company.user && data.item.company.user[0]"
                            >{{ data.item.company.user[0].name }}
                {{ data.item.company.user[0].last_name }}</span
                            >
                        </template>

                        <template #cell(fraction)="data">
                            <span v-if="data.item.fraction"> {{ data.item.fraction }}% </span>
                            <span v-else>-</span>
                        </template>
                        <template #cell(amount)="data">
              <span v-if="data.item.amount">
                {{ data.item.amount | formatAmount }}
              </span>
                            <span v-else>-</span>
                        </template>
                        <template #cell(discount)="data">
              <span v-if="data.item.discount">
                {{ data.item.discount | formatAmount }}
              </span>
                            <span v-else>-</span>
                        </template>
                        <template #cell(hive_fee)="data">
              <span v-if="data.item">
                {{ data.item.hive_fee | formatAmount }}
              </span>
                            <span v-else>-</span>
                        </template>
                        <template #cell(net_return)="data">
              <span v-if="data.item">
                {{ data.item.net_return | formatAmount }}
              </span>
                            <span v-else>-</span>
                        </template>

                        <template #cell(created_at)="data">
              <span v-if="data.item.created_at">{{
                data.item.created_at | formatDate
              }}</span>
                            <span v-else>-</span>
                        </template>
                        <template #cell(custom)="data">
                            <b-button
                                @click="
                  changeModalInfo(
                    'cancel_fraction',
                    $t('cancel_fraction'),
                    $t('are_you_sure_you_want_to_cancel_this_fraction'),
                    data.item
                  )
                "
                                class="text-end"
                                size="sm"
                                v-b-modal.approveModal
                                variant="danger"
                            >{{ $t("cancel") }}
                            </b-button
                            >
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.value == 0">{{ $t("pending") }} </span>
                            <span v-if="data.value == 1">{{ $t("approved") }}</span>
                            <span v-if="data.value == 2">{{ $t("declined") }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
            <b-row class="mt-2" v-if="item.is_reverse != 1">
                <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
                    <b-row class="justify-content-between col-md-12">
                        <b-col class="">
                            <h6>{{ $t("associated_files") }}</h6>
                        </b-col>
                    </b-row>
                    <b-col class="col-md-12">
                        <div class="mt-1 row col-md-12">
                            <h5 class="mr-10">
                                <b-link class="web">
                                    <feather-icon
                                        @click="readInvoice(invoice)"
                                        class="pointer"
                                        icon="PaperclipIcon"
                                        size="22"
                                    />
                                </b-link>
                                <b-link class="mobile">
                                    <feather-icon
                                        @click="downloadInvoice(invoice)"
                                        class="pointer"
                                        icon="PaperclipIcon"
                                        size="22"
                                    />
                                </b-link>
                                {{ invoice.title }}
                            </h5>
                            <div class="ml-10" style="margin-left: 40px">
                                {{ invoice.created_at | formatDate }}
                            </div>
                        </div>
                    </b-col>

                    <b-col
                        class="col-md-12"
                        v-if="item.associate_files && item.associate_files.length > 0"
                    >
                        <div
                            :key="index"
                            class="mt-1 row col-md-12"
                            v-for="(item, index) in item.associate_files"
                        >
                            <h5 class="mr-10">
                                <b-link class="web">
                                    <feather-icon
                                        @click="downloadFile(item)"
                                        class="pointer"
                                        icon="PaperclipIcon"
                                        size="22"
                                    />
                                </b-link>

                                <b-link class="mobile">
                                    <feather-icon
                                        @click="downloadFile(item)"
                                        class="pointer"
                                        icon="PaperclipIcon"
                                        size="22"
                                    />
                                </b-link>
                                {{ returnTitle(item.title) }}

                            </h5>
                            <div class="ml-10" style="margin-left: 40px">
                                {{ item.created_at | formatDate }}
                            </div>
                        </div>
                    </b-col>
                </b-col>
            </b-row>
            <div class="mt-3" v-if="item.is_reverse != 1">
                <div class="blue-jumbotron col-md-12 mt-1 pt-2 pb-2">
                    <b-row class="col-md-12">
                        <b-col
                            @click="triggerFileInput"
                            class="col-md-12 text-center pointer"
                        >
                            <feather-icon icon="DownloadIcon" size="22"/>
                            {{ $t("upload_a_file_or_drag_files_here") }}
                        </b-col>
                        <div style="display: none">
                            <b-form-file
                                @change="uploadFile($event)"
                                class="mt-1 mb-2 col-md-3"
                                label="Upload Associated Invoice"
                                ref="fileInput1"
                                v-model="associated"
                            ></b-form-file>
                        </div>
                    </b-row>
                    <b-row class="text-center"></b-row>
                </div>
            </div>

            <b-modal centered hide-footer v-model="uploadFileModal">
                <div>
                    <h4 class="font-weight-bolder">
                        {{ $t("assign_new_document_to_invoice") }}
                    </h4>
                </div>
                <div>
                    <h5 class="mt-1 mb-1">
                        <feather-icon class="pointer" icon="PaperclipIcon" size="20"/>
                        {{ $t("file") }}
                        :
                        {{
                        uploadedInvoce && uploadedInvoce.file
                        ? uploadedInvoce.file.name
                        : ""
                        }}
                    </h5>
                </div>
                <div class="mt-1">
                    {{ $t("file_description") }}:
                    <b-form-input v-model="fileDesc"></b-form-input>
                </div>
                <div class="mt-1">
                    {{ $t("type") }}:
                    <b-form-select
                        :options="fileTypeOptions"
                        v-model="fileType"
                    ></b-form-select>
                </div>
                <div align="end" class="col-md-12 pr-0 mt-3">
                    <b-button @click="storeData" class="mt-1" variant="primary">
                        {{ $t("upload") }}
                    </b-button
                    >
                </div>
            </b-modal>
        </div>

        <AuditTrial :item="item" @showDetails="showDetails" v-else/>
        <ApproveModal :textContent="modalText" :title="modalTitle" :action="approveModalAction" :invoice="item"/>

        <AddNewCompnayModal
            :company="companyZefixData"
            :invoice="item"
            :showDialog="addNewComp"
            @closeAddCompModal="closeAddCompModal"
            @showSettings="showSettings"
        ></AddNewCompnayModal>

        <MainContactModal
            :company="companyZefixDataMainContact"
            :invoice="item"
            :showDialog="MainContact"
            @closeMainContactModal="closeMainContactModal"
            @showSettings="showSettings"
        ></MainContactModal>
        <b-modal
            hide-header-close
            no-close-on-backdrop
            scrollable
            size="lg"
            v-model="scoringModal"
        >
            <template #modal-footer="{}">
                <b-button @click="cancel()" size="sm" variant="outline-primary">
                    {{ $t("back") }}
                </b-button>
                <b-button @click="saveCustomScoring()" size="sm" variant="primary">
                    {{ $t("save") }}
                </b-button>
            </template>
            <p class="">
            <h5>Correct Invoice Information</h5>
            <div class="box">
                Seller Score: <b>{{item.seller_score}}</b>
                <br/>
                Debtor Score: <b>{{item.debtor_score}}</b>
                <br/>
                Terms: <b>{{item.terms}}</b>
                <br/>
                Due Days: <b>{{item.due_days}}</b>
                <br/>
                Amount: <b>{{item.amount}}</b>
                <br/>
                <hr/>
            </div>
            <validation-observer
                name="Scoring"
                ref="scoringValidation"
            >
                <b-form>
                    <h5>{{ $t("custom_scoring") }}</h5>
                    <b-card>
                        <b-row m-0 p-0>
                            <b-col cols="12" lg="6" md="6" xl="6">
                                <b-form-group
                                    :label="$t('seller_score') + ':'"
                                    id="seller-score"
                                    label-for="seller-score"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Seller Score"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">

                                            <b-form-input
                                                :state="errors.length > 0 ? false : null"
                                                id="seller-score"
                                                required
                                                v-model="custom_scoring.seller_score"
                                            ></b-form-input>
                                        </b-input-group>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6" md="6" xl="6">
                                <b-form-group
                                    :label="$t('debtor_score') + ':'"
                                    id="debtor-score"
                                    label-for="debtor-score"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Debtor Score"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">

                                            <b-form-input
                                                :state="errors.length > 0 ? false : null"
                                                id="main-contact-first-name"
                                                required
                                                v-model="custom_scoring.debtor_score"
                                            ></b-form-input>
                                        </b-input-group>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row m-0 p-0>
                            <b-col cols="12" lg="6" md="6" xl="6">
                                <b-form-group
                                    :label="$t('terms') + ':'"
                                    id="terms"
                                    label-for="terms"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Terms"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">

                                            <b-form-input
                                                :state="errors.length > 0 ? false : null"
                                                id="terms"
                                                required
                                                v-model="custom_scoring.terms"
                                            ></b-form-input>
                                        </b-input-group>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6" md="6" xl="6">
                                <b-form-group
                                    :label="$t('due_days') + ':'"
                                    id="due-days"
                                    label-for="due-days"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Due Days"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">

                                            <b-form-input
                                                :state="errors.length > 0 ? false : null"
                                                id="due-days"
                                                required
                                                v-model="custom_scoring.due_days"
                                            ></b-form-input>
                                        </b-input-group>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row m-0 p-0>
                            <b-col cols="12" lg="6" md="6" xl="6">
                                <b-form-group
                                    :label="$t('amount') + ':'"
                                    id="amount"
                                    label-for="amount"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Amount"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">

                                            <b-form-input
                                                :state="errors.length > 0 ? false : null"
                                                disabled
                                                id="seller-score"
                                                required
                                                v-model="custom_scoring.amount"
                                            ></b-form-input>
                                        </b-input-group>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>
                    </b-card>
                </b-form>
            </validation-observer>
            </p>
        </b-modal>
        <b-modal
            hide-header-close
            no-close-on-backdrop
            scrollable
            size="lg"
            v-model="selectNewDebtor"
        >
            <template #modal-footer="{}">
                <b-button @click="cancelSelectedDebtor()" size="sm" variant="outline-primary">
                    {{ $t("back") }}
                </b-button>
                <b-button @click="saveNewDebtor()" size="sm" variant="primary">
                    {{ $t("save") }}
                </b-button>
            </template>
            <p class="">
                <validation-observer
                    name="newDebtor"
                    ref="new_debtor"
                >
                    <b-form>
                        <h5>{{ $t("select_debtor") }}</h5>
                        <b-card>
                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group
                                    :label="$t('debtor') + ':'"
                                    class="font-weight-bolder"
                                    id="debtor"
                                    label-for="debtor"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="debtor"
                                        rules="required"
                                    >
                                        <b-form-select
                                            :options="companies"
                                            text-field="name"
                                            v-model="selected_company"
                                            value-field="id"
                                        >
                                        </b-form-select>

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-card>
                    </b-form>
                </validation-observer>
                <validation-observer
                    name="RegisterCompany"
                    ref="companyRegisterValidation"
                >
                    <b-form v-if="selected_company">
                        <h5>{{ $t("main_contact_person") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('salutation') + ':'"
                                        id="main-contact-gender-group"
                                        label-for="main-contact-gender"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-input-group-prepend is-text>
                                                <feather-icon class="cursor-pointer" icon="UserIcon"/>
                                            </b-input-group-prepend>
                                            <b-form-select
                                                :options="[
                          {
                            text: $t('select_one'),
                            value: null,
                            disabled: true,
                          },
                          { text: $t('mr'), value: '0' },
                          { text: $t('mrs'), value: '1' },
                        ]"
                                                id="main-contact-gender"
                                                required
                                                v-model="main_contact_gender"
                                            ></b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('company_email_address') + ':'"
                                        id="main-contact-email-group"
                                        label-for="main-contact-email"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Number of Debtors"
                                            rules="required|email"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="MailIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-email"
                                                    required
                                                    v-model="main_contact_email"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('first_name') + ':'"
                                        id="main-contact-first-name-group"
                                        label-for="main-contact-first-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="First name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="UserIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-first-name"
                                                    required
                                                    v-model="main_contact_first_name"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('tel') + ':'"
                                        id="main-contact-tel-group"
                                        label-for="main-contact-tel"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="tel"
                                            rules="required"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend id="phoneCountries">
                                                    <b-form-select
                                                        :options="mobileCountries"
                                                        v-model="phoneContries"
                                                    >
                                                    </b-form-select>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :state="errors.length > 0 ? false : null"
                                                    @input="checkPhoneFormat"
                                                    class="form-control"
                                                    id="main-contact-tel"
                                                    v-model="main_contact_tel"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="phoneValidation">{{
                                                phoneValidation
                                                }}
                                            </small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('last_name') + ':'"
                                        id="main-contact-last-name-group"
                                        label-for="main-contact-last-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Last Name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="UserIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-last-name"
                                                    required
                                                    v-model="main_contact_last_name"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('position_in_the_company') + ':'"
                                        id="main-contact-position-group"
                                        label-for="main-contact-position"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Position in the Company"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="BriefcaseIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-position"
                                                    required
                                                    v-model="main_contact_position"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-form>
                </validation-observer>
            </p>
        </b-modal>
        <b-modal
            hide-header-close
            no-close-on-backdrop
            scrollable
            size="lg"
            v-model="newDebtor"
        >
            <template #modal-footer="{}">
                <b-button @click="cancelNewDebtor()" size="sm" variant="outline-primary">
                    {{ $t("back") }}
                </b-button>
                <b-button @click="saveNewDebtor()" size="sm" variant="primary">
                    {{ $t("save") }}
                </b-button>
            </template>
            <p class="">
                <validation-observer
                    name="RegisterCompany"
                    ref="companyRegisterValidation"
                >
                    <b-form>
                        <h5>{{ $t("main_contact_person") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('salutation') + ':'"
                                        id="main-contact-gender-group"
                                        label-for="main-contact-gender"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-input-group-prepend is-text>
                                                <feather-icon class="cursor-pointer" icon="UserIcon"/>
                                            </b-input-group-prepend>
                                            <b-form-select
                                                :options="[
                          {
                            text: $t('select_one'),
                            value: null,
                            disabled: true,
                          },
                          { text: $t('mr'), value: '0' },
                          { text: $t('mrs'), value: '1' },
                        ]"
                                                id="main-contact-gender"
                                                required
                                                v-model="main_contact_gender"
                                            ></b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('company_email_address') + ':'"
                                        id="main-contact-email-group"
                                        label-for="main-contact-email"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Number of Debtors"
                                            rules="required|email"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="MailIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-email"
                                                    required
                                                    v-model="main_contact_email"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('first_name') + ':'"
                                        id="main-contact-first-name-group"
                                        label-for="main-contact-first-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="First name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="UserIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-first-name"
                                                    required
                                                    v-model="main_contact_first_name"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('tel') + ':'"
                                        id="main-contact-tel-group"
                                        label-for="main-contact-tel"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="tel"
                                            rules="required"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend id="phoneCountries">
                                                    <b-form-select
                                                        :options="mobileCountries"
                                                        v-model="phoneContries"
                                                    >
                                                    </b-form-select>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :state="errors.length > 0 ? false : null"
                                                    @input="checkPhoneFormat"
                                                    class="form-control"
                                                    id="main-contact-tel"
                                                    v-model="main_contact_tel"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="phoneValidation">{{
                                                phoneValidation
                                                }}
                                            </small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('last_name') + ':'"
                                        id="main-contact-last-name-group"
                                        label-for="main-contact-last-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Last Name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="UserIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-last-name"
                                                    required
                                                    v-model="main_contact_last_name"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" xl="6">
                                    <b-form-group
                                        :label="$t('position_in_the_company') + ':'"
                                        id="main-contact-position-group"
                                        label-for="main-contact-position"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Position in the Company"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        class="cursor-pointer"
                                                        icon="BriefcaseIcon"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :disabled="disableInputs"
                                                    :state="errors.length > 0 ? false : null"
                                                    id="main-contact-position"
                                                    required
                                                    v-model="main_contact_position"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-form>
                </validation-observer>
            </p>
        </b-modal>
        <!-- change discount in % modal -->

        <b-modal :title="$t('discount_in_%')" hide-footer v-model="modalStatus">
            <b-input-group class="input-group-merge">
                <b-form-input
                    @input="changeDescountValue"
                    id="discount_in_percentage"
                    required
                    v-model="Discountdata.discount_in_percentage"
                ></b-form-input>
            </b-input-group>
            <p class="p-2 mt-1 b-radius-lg bg-light text-dark" v-if="Discountdata.new_effective_value">
                {{$t('effective_value')}}: <b>{{ amountFormat(Discountdata.new_effective_value, item.currency.name) }}</b></p>
            <p class="p-2 mt-1 b-radius-lg bg-light text-dark" v-if="Discountdata.new_discount">{{$t('discount')}}: <b>{{
                amountFormat(Discountdata.new_discount, item.currency.name) }}</b></p>
            <b-form-checkbox class="mt-1" switch v-model="Discountdata.save_new_discount_in_percentage" variant="success">
                {{ $t('save_percentage_future_invoices') }}
            </b-form-checkbox>
            <template>
                <b-button @click="changeDiscount" class="btn mt-1" variant="primary">{{ $t('change') }}</b-button>
            </template>

        </b-modal>
    </b-card>


</template>
<script>
</script>

<script>
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import {required, email} from "@validations";
  import fileDownload from "js-file-download";
  import ApproveModal from "@/components/Common/ApproveModal";
  import AuditTrial from "@/components/Invoice/AuditTrial.vue";
  import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal";
  import InvoiceStatus from "@/components/Invoice/InvoiceStatus";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import i18n from "@/libs/i18n";
  import AddNewCompnayModal from "./AddNewCompanyModal.vue";
  import MainContactModal from "./MainContactModal.vue";
  import {phone} from "phone";

  import {VueAutosuggest} from "vue-autosuggest";
  import Cleave from "vue-cleave-component";
  import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';


  export default {

    data() {
      return {
        modalStatus: false,
        reason: '',
        selectNewDebtor: false,
        company_uid: "",
        main_contact_gender: "",
        main_contact_email: "",
        main_contact_first_name: "",
        main_contact_last_name: "",
        main_contact_position: "",

        Discountdata: {
          discount_in_percentage: "",
          new_discount: "",
          new_effective_value: "",
          save_new_discount_in_percentage: false,
        },
        main_contact_tel: "",
        phoneContries: "CH",
        phoneCountriesList: [],
        countries: [],
        mobileCountries: [],
        phoneValidation: "",
        newDebtor: false,
        accept_recourse: false,
        recourse: "",
        showUpdateButton: true,
        flashSale: false,
        agreeTerms: "",
        silentFactoring: false,
        addNewComp: false,
        companyZefixData: null,
        invoiceModal: null,
        load: true,
        uploadFileModal: false,
        associated: null,
        MainContact: false,
        companyZefixDataMainContact: null,
        uploadedInvoce: {
          file: null,
          id: "",
        },
        fileTypeOptions: [
          {
            text: i18n.t("proof_of_ownership"),
            value: "Proof Of Ownership",
          },
          {
            text: i18n.t("notice_of_assignment"),
            value: "Notice Of Assignment",
          },
          {
            text: i18n.t("other"),
            value: "Other",
          },
        ],
        fileType: "",
        fileDesc: "",
        items: [],
        showHint: false,
        companyMessage: "",
        amountOptions: {
          customDelimiter: {
            delimiters: ["'", "."],
            blocks: [2, 3, 2],
            uppercase: true,
          },
        },
        amount: "",
        fields: [
          {
            label: this.$t("score"),
            key: "score",
            variant: "danger",
            thClass: "bg-light-danger text-dark",
          },
          {
            label: this.$t("days_to_due_refactor"),
            key: "terms",
            variant: "danger",
            thClass: "bg-light-danger text-dark",
          },
          {
            label: this.$t("date_of_scoring"),
            key: "created_at",
            variant: "danger",
            thClass: "bg-light-danger text-dark",
          },
          {
            label: this.$t("seller"),
            key: "seller",
            variant: "danger",
            thClass: "bg-light-danger text-dark",
          },
          {
            label: this.$t("debtor"),
            key: "debtor",
            variant: "danger",
            thClass: "bg-light-danger text-dark",
          },
        ],
        fractionFields: [
          {
            label: "date",
            align: "start",
            key: "created_at",
            variant: "success",
            thClass: "bg-light-success text-dark",
          },
          {
            label: "invoice_id",
            align: "start",
            key: "invoice_id",
            variant: "success",
            thClass: "bg-light-success text-dark",
          },

          {
            label: "buyer",
            align: "start",
            key: "buyer",
            variant: "success",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },

          {
            label: "currency",
            align: "start",
            variant: "success",
            key: "currency",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },
          {
            label: "amount",
            align: "start",
            variant: "success",
            key: "amount",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },
          {
            label: "fraction",
            align: "start",
            key: "fraction",
            variant: "success",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },

          {
            label: "discount",
            align: "start",
            variant: "success",
            key: "discount",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },
          {
            label: "hive_fee",
            align: "start",
            variant: "success",
            key: "hive_fee",
            sortable: false,
            thClass: "bg-light-success text-dark",
          },
          {
            label: "net_return",
            align: "start",
            key: "net_return",
            sortable: false,
            variant: "success",
            thClass: "bg-light-success text-dark",
          },
          {
            label: "status",
            key: "status",
            customSlot: true,
            sortable: false,
            variant: "success",
            thClass: "bg-light-success text-dark",
          },
          {
            label: "",
            key: "custom",
            align: "end",
            customSlot: true,
            sortable: false,
            variant: "success",
            thClass: "bg-light-success text-right",
            tdClass: "text-right",
          },
        ],
        filteredOptions: [],
        options: [
          {
            value: 0,
            name: i18n.t("Non-Recourse"),
            title: 'non_recourse_tooltip_title'
          },
          {
            value: 1,
            name: i18n.t("Recourse"),
            title: 'recourse_tooltip_title'
          },
          {
            value: 2,
            name: i18n.t("Reverse"),
            title: 'Reverse Factoring'
          },
        ],
        companies: [],
        approveModalAction: null,
        modalText: "",
        modalTitle: "",
        auditTrial: false,
        showAutoSuggest: false,
        disabledSumbit: true,
        errorDueDate: "",
        numbersAmountError: "",
        fraction: null,
        hasOwnership: false,
        scoringModal: false,
        custom_scoring: {
          seller_score: "",
          debtor_score: "",
          terms: "",
          due_days: "",
          amount: ""
        },
        item: {},
        disableInputs: false,
        selected_company: null,

      };
    },
    components: {
      InvoiceStatus,
      StatisticCardHorizontal,
      ValidationProvider,
      ValidationObserver,
      ApproveModal,
      AddNewCompnayModal,
      AuditTrial,
      VueAutosuggest,
      Cleave,
      MainContactModal,
    },
    props: ["invoice"],
    computed: {

      disabledDates() {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return today;
      },
      mainAlert() {
        let mainAlert = {
          button: {
            title: "",
            action: "",
            text: "",
            question: "",
            variant: "",
          },
          button1: {
            title: "",
            action: "",
            text: "",
            question: "",
            variant: "",
          },
          message: "",
          showUpdate: false,
          showAlert: false,
          color: "secondary",
          icon: "",
        };
        this.showUpdateButton = false;
        if (this.item.status == 0) {
          mainAlert.button = "";
          mainAlert.button1 = "";
          mainAlert.message = "invoice_status_pending_message";
          mainAlert.showUpdate = true;
          this.showUpdateButton = true;
          mainAlert.showAlert = true;
          mainAlert.color = "secondary";
          mainAlert.icon = "LoaderIcon";
        } else if (this.item.status == 1) {
          mainAlert.button = "";
          mainAlert.button1 = "";
          mainAlert.message = "";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = false;
        } else if (this.item.status == 2) {
          mainAlert.button = {
            action: "pending",
            text: "status",
            question: "are_you_sure_you_want_start_from_begining",
            title: "start_process_from_beginning ",
            variant: "danger",
          };

          mainAlert.button1 = "";
          mainAlert.message = "invoice_status_decline_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;

          mainAlert.color = "danger";
          mainAlert.icon = "XCircleIcon";
        } else if (this.item.status == 3) {
          mainAlert.button = {
            action: "score",
            text: "score_invoice",
            question: "are_you_sure",
            title: "score",
            variant: "warning",
          };
          mainAlert.button1 = "";
          mainAlert.message = "invoice_status_scroing_required_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.color = "warning";
          mainAlert.icon = "FlagIcon";
        } else if (this.item.status == 4) {
          mainAlert.button1 = {
            action: "scoreDecline",
            text: "status",
            question: "are_you_sure_you_want_to_decline",
            title: "decline",
            variant: "danger",
          };
          mainAlert.button = {
            action: "scoreApprove",
            text: "status",
            question: "are_you_sure_you_want_to_approve",
            title: "approve",
            variant: "success",
          };
          mainAlert.message = "invoice_status_scroing_approval_required_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "AlertOctagonIcon";
          mainAlert.color = "secondary";
        } else if (this.item.status == 5) {
          // mainAlert.button = {
          //   action: "proofOfOwnership",
          //   text: "status",
          //   question: "proof_of_ownership_required",
          //   title: "request_proof_of_ownership",
          //   variant: "primary",
          // };
          mainAlert.button = "";
          mainAlert.button1 = "";
          mainAlert.message =
              "invoice_status_proof_of_ownership_required_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "FilePlusIcon";
          mainAlert.color = "primary";
        } else if (this.item.status == 6) {
          mainAlert.button = {
            action: "approve",
            text: "status",
            question: "are_you_sure_you_want_approve_for_sale",
            title: "approve_for_sale",
            variant: "success",
          };
          mainAlert.button1 = "";
          mainAlert.message = " invoice_status_ready_for_sale_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;

          mainAlert.icon = "CheckCircleIcon";
          mainAlert.color = "success";
        } else if (this.item.status == 7) {
          mainAlert.button = {
            action: "pending",
            text: "status",
            question: "are_you_sure_you_want_start_from_begining",
            title: "start_process_from_beginning ",
            variant: "primary",
          };
          mainAlert.button1 = "";
          mainAlert.message = "invoice_status_decline_from_scoring_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "RefreshCcwIcon";
          mainAlert.color = "secondary";
        } else if (this.item.status == 8) {
          mainAlert.button = {
            action: "proofOfOwnershipInProgress",
            text: "status",
            question: "are_you_sure?",
            title: "proof_of_ownership_approval",
            variant: "primary",
          };
          mainAlert.button1 = "";
          mainAlert.message = "proof_of_ownership_in_progress_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "FilePlusIcon";
          mainAlert.color = "primary";
        } else if (this.item.status == 9) {
          mainAlert.button = "";
          mainAlert.button1 = "";
          mainAlert.message = "partially_bought_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "FilePlusIcon";
          mainAlert.color = "secondary";
        } else if (this.item.status == 10) {
          mainAlert.button = {
            action: "settled",
            text: "status",
            question: "are_you_sure?",
            title: "settled",
            variant: "primary",
          };
          mainAlert.button1 = "";
          mainAlert.message = "fully_bought_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "FilePlusIcon";
          mainAlert.color = "primary";
        } else if (this.item.status == 11) {
          mainAlert.button = "";
          mainAlert.button1 = "";
          mainAlert.message = "invoice_payback_message";
          mainAlert.showUpdate = false;
          mainAlert.showAlert = true;
          mainAlert.icon = "FilePlusIcon";
          mainAlert.color = "success";
        }

        return mainAlert;
      },
    },
    created() {
      this.$http.post("/countries").then((res) => {
        res.data.value.map((item) => {
          this.mobileCountries.push({
            value: item.iso_code,
            text: `${item.iso_code} (${item.phone_code})`,
          });
        });
      });
      this.getCompanies();
      this.item = this.invoice;
      if (this.item.company.invoice_discount_percentage) {
        this.Discountdata.save_new_discount_in_percentage = true
      }
      this.Discountdata.discount_in_percentage = this.item.company.invoice_discount_percentage;
      if (this.item) {
        this.custom_scoring.amount = this.invoice.amount.replaceAll("'", "");
        this.amount = this.item.amount;
        this.load = false;
        if (this.item.associate_files.length > 0) {
          const hasProofType = this.item.associate_files.some(item => item.hasOwnProperty('type') && item.type === 'Proof Of Ownership');
          if (hasProofType) {
            this.hasOwnership = true;
          } else {
            this.hasOwnership = false;
          }
        }

        if (this.item.flash_sale == 0) {
          this.flashSale = false;
        } else {
          this.flashSale = true;
        }

        if (this.item.silent == 0) {
          this.silentFactoring = false;
        } else {
          this.silentFactoring = true;
        }

        if (this.item.is_reverse == 1) {
          this.item.recourse = 2;
        } else if (this.item.recourse == 0) {
          this.item.recourse = 0;
        } else {
          this.item.recourse = 1;
        }
        if (this.item.accept_recourse == 1) {
          this.accept_recourse = true;
        } else {
          this.accept_recourse = false;
        }
        if (this.item.issuer_id) {
          this.showAutoSuggest = true;
        }

        if (this.invoice && this.invoice.debtor) {
          this.invoice.company_name = this.item.debtor.name;
        }
        this.parsedAmount(this.item.amount);
        this.getCustomScoring();
      }
    },

    methods: {

      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      changeDiscount() {
        this.$http.post(`/invoice/admin/change_discount/${this.item.id}`, this.Discountdata).then((res) => {
          if (res) {
            if (res) {
              console.log(res)
              this.showSuccessMessage(res.data.message)
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        });
      },
      changeDescountValue() {
        let number = parseFloat(this.item.amount.replace("'", ""));
        this.Discountdata.new_discount = number * this.Discountdata.discount_in_percentage / 100
        this.Discountdata.new_effective_value = number - this.Discountdata.new_discount
      },
      cancelSelectedDebtor() {
        this.selectNewDebtor = false;
        this.selected_company = null;
      },
      saveSelectedDebtor() {
        this.selectNewDebtor = false;
        this.newDebtor = true;
      },
      checkPhoneFormat() {
        let validation = phone(this.main_contact_tel, {
          country: this.phoneContries,
          validateMobilePrefix: false
        });

        if (validation && validation.isValid == false) {
          this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
        } else {
          this.phoneValidation = "";
        }
      },
      cancelNewDebtor() {

      },
      saveNewDebtor() {

        this.$refs.companyRegisterValidation.validate().then((success) => {
          if (success && this.phoneValidation.length < 1) {
            this.disableInputs = true;
            const formSubmissionData = {
              company_id: this.selected_company,
              main_contact_first_name: this.main_contact_first_name,
              main_contact_last_name: this.main_contact_last_name,
              main_contact_position: this.main_contact_position,
              main_contact_tel: this.main_contact_tel,
              main_contact_email: this.main_contact_email,
              main_contact_gender: this.main_contact_gender,
            };

            this.$http.post(`/invoice/admin/addDebtor/${this.$route.params.id}`, formSubmissionData).then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("success"),
                      icon: "SuccessIcon",
                      variant: "success",
                    },
                  });
                  this.newDebtor = false;
                  window.location.reload();
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message
                          ? res.data.message
                          : "Something went wrong, please try again",
                      icon: "ErrorIcon",
                      variant: "danger",
                    },
                  });
                }

              }
            });
          }
          this.disableInputs = false;
        });
      },
      getCustomScoring() {

        this.$http
            .post("/invoice/admin/getCustomScoring/" + this.$route.params.id)
            .then((response) => {
              if (response.data.value) {
                this.custom_scoring.seller_score = response.data.value.seller_score;
                this.custom_scoring.debtor_score = response.data.value.debtor_score;
                this.custom_scoring.terms = response.data.value.terms;
                this.custom_scoring.due_days = response.data.value.due_days;
              }

            });
      },
      cancel() {
        this.scoringModal = false;
        this.custom_scoring = {
          seller_score: "",
          debtor_score: "",
          terms: "",
          due_days: "",
          amount: "",
        };
        this.custom_scoring.amount = this.invoice.amount.replaceAll("'", "");
      },
      openCustomScoring() {
        this.scoringModal = true;
      },
      saveCustomScoring() {
        console.log('custom_scoring', this.custom_scoring)

        this.$http
            .post("/invoice/admin/custom-score/" + this.$route.params.id, this.custom_scoring)
            .then((response) => {
              if (response) {
                window.location.reload();
              }
            });
      },
      readInvoice(item) {
        this.$http
            .post(
                "/invoice/admin/download/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              let title = item.title + '.pdf'
              fileDownload(response.data, title);
            })
            .catch((error) => {
            });
        // this.$http
        //   .post("/invoice/admin/read/" + item.id, {
        //     responseType: "json",
        //   })
        //   .then((response) => {
        //     this.$store.commit("app/SET_FILE_TYPE", response.data.value.ext);
        //     this.$store.commit("app/SET_FILE_BASE64", response.data.value.b64);

        //     window.open("/read-files", "_blank");
        //   });
      },
      returnTitle(title) {
        return this.$t(title);
      },
      readFile(item) {
        this.$http
            .post(
                "/invoice/admin/download/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              let title = item.title + '.pdf'
              fileDownload(response.data, title);
            })
            .catch((error) => {
            });
        // this.$http
        //   .post("/invoice/admin/associate/read/" + item.id, {
        //     responseType: "json",
        //   })
        //   .then((response) => {
        //     this.$store.commit("app/SET_FILE_TYPE", response.data.value.ext);
        //     this.$store.commit("app/SET_FILE_BASE64", response.data.value.b64);

        //     window.open("/read-files", "_blank");
        //   });
      },
      closeMainContactModal(data) {
        if (data != null) {
          this.invoice.issuer_id = data[0].company_id;
        } else {
          this.invoice.company_name = "";
        }
        this.$bvModal.hide("MainContact");
      },
      parsedAmount1($event, val) {
        this.numbersAmountError = "";
        let regex = /^[0-9']*(.)?[0-9]*$/;
        if (regex.test(val)) {
          setTimeout(() => {
            if (val == this.item.amount && val) {
              if (this.item.amount.includes("'")) {
                this.item.amount = this.item.amount.replace(/'/gi, "");
              }
              this.item.amount = Number(this.item.amount)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&'");
            }
          }, 1000);
        } else {
          this.numbersAmountError = this.$t("only_numbers_allowed");
          $event.preventDefault();
          return;
        }
      },
      parsedAmount(val) {
        setTimeout(() => {
          if (val == this.item.amount && val) {
            if (this.item.amount.includes("'")) {
              this.item.amount = this.item.amount.replace(/'/gi, "");
            }
            this.item.amount = Number(this.item.amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&'");
          }
        }, 1000);
      },
      closeAddCompModal(data) {
        //this.getCompanies();
        if (data != null) {
          this.invoice.company_name = data[0].name;
          this.invoice.uid = data[0].uid;
          this.invoice.issuer_id = data[0].id;
        } else {
          this.invoice.company_name = "";
        }
        this.$bvModal.hide("AddCompany");
      },
      showSettings() {
        this.addNewComp = false;
        this.openModal = false;
      },
      onSelected(val, item) {
        // value.company_name =
        item.company_name = val.item;

        this.getCompanyDataFromZefix(item);
        // this.successMessage = false;
      },
      getCompanyDataFromZefix(item) {
        const self = this;
        if (typeof this.companies[item.company_name] != "undefined") {
          this.$http
              .post("/get-company-data-from-zefix", {
                search: this.companies[item.company_name],
              })
              .then((res) => {
                if (res) {
                  item.company_uid = res.data.value.uid;
                  let data = {
                    search: item.company_uid,
                    invoice_id: item.id,
                  };

                  this.$http.post("/invoice/getCompany", data).then((res) => {
                    if (res) {
                      console.log(res.data.value.from);
                      if (res.data.value && res.data.value.from == "Database") {
                        item.issuer_id = res.data.value.company.id;
                      } else if (
                          res.data.value &&
                          res.data.value.from == "ContactPerson"
                      ) {
                        this.showMainContactModal(item, res.data.value.company);
                      } else {
                        this.showModal(item, res.data.value.company);
                      }
                      this.showUpdateButton = true;
                    }
                  });
                }
              });
        }
      },
      showModal(item, company) {
        this.companyZefixData = company;
        this.$bvModal.show("AddCompany");
      },

      showMainContactModal(item, company) {
        this.companyZefixDataMainContact = company;
        this.$bvModal.show("MainContact");
      },

      searchForCompaniesName(item) {
        this.companyMessage = "";
        clearTimeout(this.timeout);
        clearTimeout(this.timeout);
        const self = this;

        this.timeout = setTimeout(function () {
          if (item.company_name.length > 2) {
            self.showHint = true;
            self.companies = [];
            self.filteredOptions = [];
            self.$http
                .post("/get-companies-name-from-zefix", {
                  search: item.company_name,
                })
                .then((res) => {
                  self.successMessage = false;
                  self.filteredOptions.push({
                    data: Object.keys(res.data.value),
                  });
                  self.showHint = false;
                  self.companies = res.data.value;
                  if (res.data.value.length < 1) {
                    self.companyMessage =
                        "no_results_with_the_information_you_entered_please_try_again";
                  }
                });
          } else {
            self.showHint = false;
            self.companies = [];
            self.filteredOptions = [];
          }
        }, 1000);
      },

      downloadInvoice(item) {
        this.$http
            .post(
                "/invoice/admin/download/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              let title = item.title + '.pdf'
              console.log(title)
              fileDownload(response.data, title);
            })
            .catch((error) => {
            });
      },

      changeTerms(item) {
        if (this.item.issue_date && this.item.due_date) {
          let compareDate = "";
          const startDate = new Date(this.item.issue_date);
          const endDate = new Date(this.item.due_date);
          const differenceInTime = endDate.getTime() - startDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          let term = Math.abs(Math.round(differenceInDays));
          this.item.terms = term;

          this.errorDueDate = "";
          this.disabledSumbit = false;
        }
      },

      changeDueDate(item) {
        if (this.item.issue_date && this.item.terms) {
          let newDate = new Date(this.item.issue_date);

          let term = Number(this.item.terms);
          let parsed = new Date(this.item.issue_date);

          let compareDate = "";
          newDate.setDate(parsed.getDate() + term);
          compareDate = newDate;

          this.item.due_date = newDate.toISOString().split("T")[0];

          let max = 0;
          if (term <= 30) {
            max = 10;
          } else if (term <= 45) {
            max = 12;
          } else if (term <= 60) {
            max = 15;
          } else if (term <= 75) {
            max = 17;
          } else if (term > 75) {
            max = 20;
          }
          compareDate.setDate(compareDate.getDate() - max);
          var today = new Date();
          if (compareDate > today) {
            this.errorDueDate = "";
            this.disabledSumbit = false;
          } else {
            this.errorDueDate = this.$t("due_date_issue_text");
            this.disabledSumbit = true;
          }
        }
      },

      editInvoice() {
        if (this.numbersAmountError.length < 1) {
          let data = {
            debtor_id: this.item.company_id,
            issuer_id: this.item.issuer_id,
            issue_date: this.item.issue_date,
            due_date: this.item.due_date,
            terms: Number(this.item.terms),
            amount: this.item.amount.replace(/'/gi, ""),
            invoice_number: this.item.invoice_number,
            status: this.item.status,
            accept_recourse: this.accept_recourse,
            bic: this.item.bic,
            iban: this.item.iban,
            bank_address: this.item.bank_address,
            bank_name: this.item.bank_name
          };

          this.$http
              .post(`/invoice/admin/update/${this.$route.params.id}`, data)
              .then((res) => {
                if (res) {
                  this.showSuccessMessage(i18n.t("invoice_updated_successfully"));
                  window.location.reload();
                }

              });
        }
      },
      changeInvoiceFact(type, value) {
        let data = {};
        if (type == 'recourse' && value == 0) {
          data.data = value;
          data.accept_recourse = false;
        }
        if (type == 'recourse' && value == 1 && this.accept_recourse == false) {
          return;
        } else {
          //nth
        }
        if (type == 'accept_recourse') {
          type = value == 1 ? 'recourse' : 'non-recourse';
          data.data = value == 1 ? 1 : 0;
          data.accept_recourse = this.accept_recourse;
        } else {
          data.data = value;
        }
        this.$http
            .post(`/invoice/admin/change/${type}/${this.$route.params.id}`, data)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("success"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
                window.location.reload();
              }
            });
      },
      triggerFileInput() {
        this.$refs.fileInput1.$refs.input.click();
      },
      storeData() {
        let formData = new FormData();
        let config = {headers: {"Content-Type": "multipart/form-data"}};
        formData.append("pdf", this.associatedEvent.target.files[0]);
        this.$http
            .post(
                `/invoice/admin/associate/upload/${this.$route.params.id}`,
                formData,
                config
            )
            .then((res) => {
              if (res) {
                let data = {
                  description: this.fileDesc,
                  type: this.fileType,
                };
                this.$http
                    .post(`/invoice/admin/associateData/${res.data.value.id}`, data)
                    .then((response) => {
                      if (response) {
                        this.uploadFileModal = false;
                        this.showSuccessMessage(
                            this.$t("file_uploaded_successfully")
                        );
                        this.associated = null;
                        this.fileType = "";
                        this.fileDesc = "";
                        window.location.reload();
                      }
                    });
              }
            });
      },

      uploadFile(event) {
        this.fileType = "";
        this.fileDesc = "";
        this.uploadedInvoce.file = event.target.files[0];
        this.associatedEvent = event;
        this.uploadFileModal = true;
      },
      showDetails() {
        this.auditTrial = false;
      },
      showAduitTrial() {
        this.auditTrial = true;
      },
      scoreInvoice() {
        this.$http
            .post(`/invoice/admin/score/${this.$route.params.id}`)
            .then((res) => {
              if (res) {
                if (res.data.message) {
                  this.showSuccessMessage(res.data.message);
                  window.location.reload();
                }

                this.uploadFileModal = false;
                this.fileType = "";
                this.fileDesc = "";
              }
            });
      },
      changeModalInfo(action, title, text, item) {
        console.log(action, title, text, item);

        this.approveModalAction = action;
        this.modalTitle = title;
        this.fraction = item;
        if (action == 'activate') {
          this.allowLevel();
        } else {
          this.modalText = text;
        }
      },
      allowLevel() {
        this.$http.post(`/invoice/admin/check-level/${this.$route.params.id}`).then((response) => {
          if (response.data.value.allowed == true) {
            this.modalText = this.$t('are_you_sure_you_want_set_to_active');
          } else {
            this.modalText = this.$t('your_level_does_not_allow_to_publish_this_invoice') + ''
                + this.$t('are_you_sure_you_want_set_to_active');
          }
        });
      },
      callApproveModalAction() {
        if (this.approveModalAction == "archive") {
          this.changeStatus("archive");
        } else if (this.approveModalAction == "remove") {
          this.changeStatus("remove");
        } else if (this.approveModalAction == "approve") {
          this.changeStatus("approve");
        } else if (this.approveModalAction == "status") {
          this.changeStatus("status");
        } else if (this.approveModalAction == "pending") {
          this.changeStatus("pending");
        } else if (this.approveModalAction == "scoreDecline") {
          this.changeStatus("scoreDecline");
        } else if (this.approveModalAction == "scoreApprove") {
          this.changeStatus("scoreApprove");
        } else if (this.approveModalAction == "proofOfOwnership") {
          this.changeStatus("proofOfOwnership");
        } else if (this.approveModalAction == "proofOfOwnershipInProgress") {
          this.changeStatus("proofOfOwnershipInProgress");
        } else if (this.approveModalAction == "settled") {
          this.changeStatus("settled");
        } else if (this.approveModalAction == "score") {
          this.scoreInvoice();
        } else if (this.approveModalAction == "cancel_fraction") {
          this.cancelFraction();
        } else if (this.approveModalAction == "send_initial_debtor_confimration") {
          this.sendInitialDeborMail();
        } else if (this.approveModalAction == "send_supplier_confirmation") {
          this.sendSupplierMail();
        } else if (this.approveModalAction == "activate") {
          this.changeStatus("status");
        } else if (this.approveModalAction == "hide_from_market") {
          this.changeMarketVisibility();
        }else if(this.approveModalAction == 'send_offer') {
            this.SendAnOffer();
        }
      },

      SendAnOffer() {
        this.$http
            .post(`/invoice/admin/send_offer/${this.$route.params.id}`)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("success"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
                window.location.reload();
              }
            });
      },

      changeMarketVisibility() {
        this.$http
            .post(`/invoice/admin/change/hide_from_market/${this.$route.params.id}`, {
              value: !this.item.hide_from_market
            })
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("success"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
                window.location.reload();
              }
            });
      },
      sendSupplierMail() {
        this.$http
            .post(`/invoice/admin/sendSupplierEmail/${this.$route.params.id}`)
            .then((res) => {
              if (res) {
                window.location.reload();
              }
            });
      },
      sendInitialDeborMail() {
        this.$http
            .post(`/invoice/admin/sendDebtorEmail/${this.$route.params.id}`)
            .then((res) => {
              if (res) {
                window.location.reload();
              }
            });
      },
      cancelFraction() {
        this.$http
            .post(`/invoice/admin/fraction/${this.fraction.id}/cancel`)
            .then((res) => {
              if (res) {
                window.location.reload();
              }

            });
      },
      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$http
            .post("/invoice/admin/show/" + this.$route.params.id, {})
            .then((response) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: text,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.load = false;

              if (response.data.status == 200) {
                this.invoice = response.data.value;
                this.parsedAmount(this.invoice.amount);
                this.invoice.company_name = response.data.value.debtor.name;
              }

              if (type == "remove") {
                this.$router.replace("/invoices");
              }
            })
            .catch((error) => {
              this.load = false;
            });
      },
      downloadFile(item) {
        this.$http
            .post(
                "/invoice/admin/associate/download/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              let parsed = response.data.type.split("/");
              let str = "doc." + parsed[1];
              let title = item.title + '.pdf';
              fileDownload(response.data, title);
            })
            .catch((error) => {
            });
      },
      forceActivate(item) {
        this.$http
            .post(`/invoice/admin/forceActive/${this.$route.params.id}`, {
              status: status,
            })
            .then((res) => {
              if (res) {
                this.showSuccessMessage('success');

                window.location.reload();
              }
            });
      },
      changeStatus(type) {
        let status = 0;
        let message = "";
        let route = "";
        if (type == "status") {
          if (this.item.status == 1) {
            status = 0;
          } else {
            status = 1;
          }
          message = "status_changed_successfully";
        }

        if (type == "archive") {
          status = 2;
          message = "invoice_has_been_successfully_archived";
        }

        if (type == "remove") {
          status = 2;
          route = "remove";
          message = "invoice_has_been_successfully_removed";
        }
        if (type == "approve") {
          status = 1;
          message = "the_invoce_has_been_approved";
        }
        if (type == "pending") {
          status = 0;
          message = "success";
        }
        if (type == "scoreApprove") {
          status = 5;
          message = "success";
        }
        if (type == "proofOfOwnershipInProgress") {
          status = 6;
          message = "success";
        }
        if (type == "settled") {
          status = 11;
          message = "success";
        }
        //  if (type == "proofOfOwnership") {
        //   status = 8;
        //   message = "success";
        // }
        if (type == "scoreDecline") {
          status = 7;
          message = "success";
        }

        //0: inactive
        // 1: active ready for sale
        // 2: archive
        // 99: remove

        this.$http
            .post(`/invoice/admin/changeStatus/${this.$route.params.id}`, {
              status: status,
              reason: this.reason
            })
            .then((res) => {
              if (res) {
                if (res.data.message) {
                  this.showSuccessMessage(this.$t(res.data.message), route);
                } else if (res.data.value.msg) {
                  this.showSuccessMessage(this.$t(res.data.value.msg), route);
                } else {
                  this.showSuccessMessage(this.$t(message), route);
                }
                window.location.reload();
              }
            });
      },

      getCompanies() {
        this.$http.post("/company/admin/allList", {}).then((response) => {
          if (response.data.status == 200) {
            this.companies = response.data.value;
          }
        });
      },
    },
  };
</script>
<style>
    .scorings-table thead th {
        background: red !important;
    }
</style>
<style lang="scss">
    @import "@core/scss/vue/libs/vue-autosuggest.scss";

    #fraction-table thead tr th {
        border: none !important;
    }

    #fraction-table tbody tr:last-child td:first-child {
        border-radius: none !important;
    }
</style>
