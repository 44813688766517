<template>
  <div class="row-height">
    <b-row class="col-md-12">
      <b-col>
        <h3 class="font-weight-bolder">
          <b-link class="btn btn-outline-primary" @click="$emit('showDetails')">
            <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
            {{ $t("back") }}
          </b-link>
          {{ $t("invoice_id") }} : I-{{ item.id }}
        </h3>
      </b-col>
    </b-row>
    <b-row v-if="item.scorings" class="mt-2">
      <b-row class="col-md-12">
        <b-col md="12">
          <b-table class="col-md-12 table" :items="item.scorings">
            <template #cell(id)="data">
              <span>AT-{{ data.item.id }}</span>
            </template>
            <template #cell(invoice_id)="data">
              <span>I-{{ data.item.invoice_id }}</span>
            </template>
            <template #cell(created_at)="data">
              <span>{{ data.item.created_at | formatDate }}</span>
            </template>
            <template #cell(updated_at)="data">
              <span>{{ data.item.updated_at | formatDate }}</span>
            </template>
          </b-table>
        </b-col>

        <b-modal
          centered
          v-model="dialog"
          size="lg"
          id="modal-center"
          :title="$t('audit_trial')"
        >
          <b-row v-if="auditTrial">
            <b-col md="8">
              <div>
                {{ $t("scoring") }}:

                <span class="font-weight-bold"> {{ auditTrial.scoring }}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                {{ $t("decision") }}:
                <span class="font-weight-bold"> {{ auditTrial.decision }}</span>
              </div>
            </b-col>

            <b-col md="8">
              <div>
                {{ $t("performed_by") }}:

                <span
                  class="font-weight-bold"
                  v-if="auditTrial.performed_by != 0"
                  >{{ auditTrial.user.name }}
                  {{ auditTrial.user.last_name }}</span
                >
                <span class="font-weight-bold" v-else> {{ $t("system") }}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                {{ $t("score_points_pre_update") }}:
                <span class="font-weight-bold">{{
                  auditTrial.score_points_pre_update
                }}</span>
              </div>
            </b-col>

            <b-col md="8">
              <div>
                {{ $t("performed_at") }}:
                <span class="font-weight-bold">{{
                  auditTrial.datetime_of_performance
                }}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                {{ $t("score_points_post_update") }}:

                <span class="font-weight-bold">{{
                  auditTrial.score_points_post_update
                }}</span>
              </div>
            </b-col>
            <br />
            <b-col class="mt-1 p-0">
              <table class="table table-striped">
                <tr>
                  <td>{{ $t("parameter") }}</td>
                  <td>{{ $t("source") }}</td>
                  <td>{{ $t("dimension") }}</td>
                  <td>{{ $t("value") }}</td>
                  <td>{{ $t("point") }}</td>
                </tr>
                <tr
                  v-for="(item, index) in auditTrial.information.values"
                  :key="index"
                >
                  <td
                    class="font-weight-bold"
                    v-html="item[0] ? item[0] : '/'"
                  ></td>
                  <td
                    class="font-weight-bold"
                    v-html="item[1] ? item[1] : '/'"
                  ></td>
                  <td
                    class="font-weight-bold"
                    v-html="item[2] ? item[2] : '/'"
                  ></td>
                  <td
                    class="font-weight-bold"
                    v-html="item[3] ? item[3] : '/'"
                  ></td>
                  <td
                    class="font-weight-bold"
                    v-html="item[4] ? item[4] : '/'"
                  ></td>
                </tr>
              </table>
            </b-col>
            <b-col md="12">
              <div>
                {{ $t("rule") }}:

                <div v-for="(item, index) in auditTrial.rule" :key="index">
                  <span class="font-weight-bold">{{ index }}: {{ item }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
    </b-row>
  </div>
</template>
<script>
import Table from "@/components/Common/Table2.vue";

export default {
  components: { Table },
  props: ["item"],
  data() {
    return {
      auditTrial: null,
      dialog: false,
      load: false,
      url: `/company/admin/scoring-history/${this.$route.params.id}`,
      headers: [
        {
          text: "Invoice Id",
          value: "invoice_id",
          key: "invoice_id",
        },
        {
          text: "Amount",
          value: "amount",
          key: "amount",
        },
        {
          text: "Amount with discount",
          value: "amount_with_discount",
          key: "amount_with_discount",
        },
        {
          text: "Rating",
          value: "rating",
          key: "rating",
        },
        {
          text: "Discount in percentage",
          value: "discount_in_percentage",
          key: "discount_in_percentage",
        },
      ],
    };
  },
  methods: {
    getAuditTrial(data) {
      this.$http
        .post(`/company/admin/scoring-trail/${data.item.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.auditTrial = res.data.value;
            this.auditTrial.information = JSON.parse(
              this.auditTrial.information
            );
            this.auditTrial.rule = JSON.parse(this.auditTrial.rule);
          } else {
            this.noHistoryFound = res.data.message;
          }
        });
    },
  },
};
</script>
<style >
</style>
