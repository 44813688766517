<template>
  <div>
    <b-modal
      id="MainContact"
      ref="MainContact"
      scrollable
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <template #modal-footer="{}">
        <b-button size="sm" variant="outline-primary" @click="cancel">
          {{ $t("back") }}
        </b-button>
        <b-button size="sm" variant="primary" @click="saveCompanyInfo">
          {{ $t("save") }}
        </b-button>
      </template>
      <p class="">
        <validation-observer
          ref="companyRegisterValidation"
          name="RegisterCompany"
        >
          <b-form>
            <h5>{{ $t("main_contact_person") }}</h5>
            <b-card>
              <b-row m-0 p-0>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-gender-group"
                    :label="$t('salutation') + ':'"
                    label-for="main-contact-gender"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select
                        id="main-contact-gender"
                        v-model="main_contact_gender"
                        :options="[
                          {
                            text: $t('select_one'),
                            value: null,
                            disabled: true,
                          },
                          { text: $t('mr'), value: '0' },
                          { text: $t('mrs'), value: '1' },
                        ]"
                        required
                      ></b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-email-group"
                    :label="$t('company_email_address') + ':'"
                    label-for="main-contact-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Number of Debtors"
                      rules="required|email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="MailIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="main-contact-email"
                          v-model="main_contact_email"
                          required
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row m-0 p-0>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-first-name-group"
                    :label="$t('first_name') + ':'"
                    label-for="main-contact-first-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First name"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="UserIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="main-contact-first-name"
                          v-model="main_contact_first_name"
                          required
                          :state="errors.length > 0 ? false : null"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-tel-group"
                    :label="$t('tel') + ':'"
                    label-for="main-contact-tel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="tel"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend id="phoneCountries">
                          <b-form-select
                            :options="mobileCountries"
                            v-model="phoneContries"
                          >
                          </b-form-select>
                        </b-input-group-prepend>
                        <b-form-input
                          id="main-contact-tel"
                          v-model="main_contact_tel"
                          @input="checkPhoneFormat"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                        >
                        </b-form-input>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger" v-if="phoneValidation">{{
                        phoneValidation
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row m-0 p-0>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-last-name-group"
                    :label="$t('last_name') + ':'"
                    label-for="main-contact-last-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="UserIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="main-contact-last-name"
                          v-model="main_contact_last_name"
                          required
                          :state="errors.length > 0 ? false : null"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group
                    id="main-contact-position-group"
                    :label="$t('position_in_the_company') + ':'"
                    label-for="main-contact-position"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Position in the Company"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="BriefcaseIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="main-contact-position"
                          v-model="main_contact_position"
                          required
                          :state="errors.length > 0 ? false : null"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </validation-observer>
      </p>
    </b-modal>
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BAlert,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { phone } from "phone";

import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  data() {
    return {
      company_uid: "",
      main_contact_gender: "",
      main_contact_email: "",
      main_contact_first_name: "",
      main_contact_last_name: "",
      main_contact_position: "",
      main_contact_tel: "",
      phoneContries: "CH",
      phoneCountriesList: [],
      countries: [],
      phoneValidation: "",
      disableInputs: false,
      mobileCountries: [],
    };
  },
  props: ["showDialog", "invoice", "company"],
  name: "MainContact",
  watch: {
    showDialog(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.dialog = newValue;
      }
    },
  },
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.mobileCountries.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
      });
    });
  },
  methods: {
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },

    submit() {},
    cancel() {
      this.$emit("closeMainContactModal");
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    saveCompanyInfo() {
      this.$refs.companyRegisterValidation.validate().then((success) => {
        if (success && this.phoneValidation.length < 1) {
          this.disableInputs = true;
          const formSubmissionData = {
            uid: this.company.uid,
            main_contact_first_name: this.main_contact_first_name,
            main_contact_last_name: this.main_contact_last_name,
            main_contact_position: this.main_contact_position,
            main_contact_tel: this.main_contact_tel,
            main_contact_email: this.main_contact_email,
            main_contact_gender: this.main_contact_gender,
            invoice_id: this.invoice.id,
            from: "ContactPerson",
          };

          this.$http.post("/debtor/store", formSubmissionData).then((res) => {
            if (res) {
              if (res.data.value) {
                this.$emit("closeMainContactModal", res.data.value);
              }
           
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message
                    ? res.data.message
                    : "Something went wrong, please try again",
                },
              });
            }
          });
        }
        this.disableInputs = false;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = 0;
      this.countries = [];
    },
  },
};
</script>

<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>









